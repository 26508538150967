import React, { forwardRef } from 'react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'

type TurnstileProps = {
  siteKey: string
  onSuccess: () => void
}

export const TurnstileComponent = forwardRef<TurnstileInstance, TurnstileProps>(({ siteKey, onSuccess }, ref) => {
  return <Turnstile className="h-12 w-11" id="turnstile-1" siteKey={siteKey} onSuccess={onSuccess} ref={ref} />
})

TurnstileComponent.displayName = 'TurnstileComponent'
