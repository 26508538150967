import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHeartJournalPage } from '../../../../hooks/pages/heart-journal'
import { Loading } from '../../../Common'
import { GoalTrackingConfigurationContent } from '../../../GoalTrackingConfiguration'
import { Session01Wrapper } from '../session-01/Wrapper'
import { Session02Wrapper } from '../session-02/Wrapper'
import { Session03Wrapper } from '../session-03/Wrapper'
import { Session04Wrapper } from '../session-04/Wrapper'
import { Session05Wrapper } from '../session-05/Wrapper'
import { Session06Wrapper } from '../session-06/Wrapper'
import { Session07Wrapper } from '../session-07/Wrapper'
import { Session08Wrapper } from '../session-08/Wrapper'
import { Session09Wrapper } from '../session-09/Wrapper'
import { Session10Wrapper } from '../session-10/Wrapper'
import CustomAlert from '../../../Alert'

type Props = {
  buttons?: React.ReactNode
  sessionId?: any
  goalTrackingConfig?: any
}

export const ScreenHeartGoalTracking = ({ buttons, sessionId, goalTrackingConfig }: Props) => {
  const { addGoal, remainingGoalsResult } = useHeartJournalPage()

  const [isGoalAdded, setIsGoalAdded] = useState(false)

  const addNewGoal = useCallback(
    async (goal_id: number) => {
      await addGoal(goal_id)
      setIsGoalAdded(true)
    },
    [goalTrackingConfig?.goal_id, addGoal]
  )

  useEffect(() => {
    if (goalTrackingConfig && goalTrackingConfig?.goal_id && !isGoalAdded) {
      addNewGoal(goalTrackingConfig?.goal_id)
    }
  }, [goalTrackingConfig?.goal_id, isGoalAdded, addNewGoal])

  const screenHeartGoalTrackingSession1 = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent goalTrackingConfig={goalTrackingConfig} />
        </div>
      </Session01Wrapper>
    )
  }, [buttons, sessionId, goalTrackingConfig])

  const screenHeartGoalTrackingSession2 = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent goalTrackingConfig={goalTrackingConfig} />
        </div>
      </Session02Wrapper>
    )
  }, [buttons, sessionId, goalTrackingConfig])

  const screenHeartGoalTrackingSession3 = useMemo(() => {
    return (
      <Session03Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent goalTrackingConfig={goalTrackingConfig} />
        </div>
      </Session03Wrapper>
    )
  }, [buttons, sessionId, goalTrackingConfig])

  const screenHeartGoalTrackingSession4 = useMemo(() => {
    return (
      <Session04Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent goalTrackingConfig={goalTrackingConfig} />
        </div>
      </Session04Wrapper>
    )
  }, [buttons, sessionId, goalTrackingConfig])

  const screenHeartGoalTrackingSession5 = useMemo(() => {
    return (
      <Session05Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent goalTrackingConfig={goalTrackingConfig} />
        </div>
      </Session05Wrapper>
    )
  }, [buttons, sessionId, goalTrackingConfig])

  const screenHeartGoalTrackingSession6 = useMemo(() => {
    return (
      <Session06Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent />
        </div>
      </Session06Wrapper>
    )
  }, [buttons, sessionId])

  const screenHeartGoalTrackingSession7 = useMemo(() => {
    return (
      <Session07Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent />
        </div>
      </Session07Wrapper>
    )
  }, [buttons, sessionId])

  const screenHeartGoalTrackingSession8 = useMemo(() => {
    return (
      <Session08Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent />
        </div>
      </Session08Wrapper>
    )
  }, [buttons, sessionId])

  const screenHeartGoalTrackingSession9 = useMemo(() => {
    return (
      <Session09Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent />
        </div>
      </Session09Wrapper>
    )
  }, [buttons, sessionId])

  const screenHeartGoalTrackingSession10 = useMemo(() => {
    return (
      <Session10Wrapper buttons={buttons}>
        <div className="space-y-6">
          <GoalTrackingConfigurationContent />
        </div>
      </Session10Wrapper>
    )
  }, [buttons, sessionId])

  return isGoalAdded && sessionId ? (
    <>
      {remainingGoalsResult?.error && (
        <div className="fixed right-5 top-5 z-50">
          <CustomAlert />
        </div>
      )}
      {sessionId === 1 && screenHeartGoalTrackingSession1}
      {sessionId === 2 && screenHeartGoalTrackingSession2}
      {sessionId === 3 && screenHeartGoalTrackingSession3}
      {sessionId === 4 && screenHeartGoalTrackingSession4}
      {sessionId === 5 && screenHeartGoalTrackingSession5}
    </>
  ) : sessionId > 5 && sessionId < 11 ? (
    <>
      {sessionId === 6 && screenHeartGoalTrackingSession6}
      {sessionId === 7 && screenHeartGoalTrackingSession7}
      {sessionId === 8 && screenHeartGoalTrackingSession8}
      {sessionId === 9 && screenHeartGoalTrackingSession9}
      {sessionId === 10 && screenHeartGoalTrackingSession10}
    </>
  ) : (
    <Loading />
  )
}
